import { useMemo } from 'react';
import useSWRImmutable from 'swr/immutable';

import type { SearchResults } from 'models/search';
import { filterTruthy } from 'utils/collection';
import fetchData, { API_URL } from 'utils/fetchData';
import { pushToGTM } from 'utils/GoogleTagManager';
import { empty } from 'utils/helpers';

import { useMaxWidth } from './useMediaQuery';

const SEARCH_BRANDS_COUNT = 4;
const SEARCH_CATEGORIES_COUNT = 3;

function getSearchUrl(
	query: string,
	{
		pageSize = 5,
		withCategoriesAndBrands = false,
	}: { pageSize?: number; withCategoriesAndBrands?: boolean } = {},
) {
	const categoriesBrandsParams = withCategoriesAndBrands
		? `&maxReturnedBrands=${SEARCH_BRANDS_COUNT}&maxReturnedCategories=${SEARCH_CATEGORIES_COUNT}`
		: '';
	return `${API_URL}Search?searchString=${query}&page-size=${pageSize}${categoriesBrandsParams}&directSearch=true`;
}

/**
 * @param queryOrUrl - Full search API URL or just the search query.
 * @param sendGTM - If GTM events should be sent for relevant results.
 */
export async function fetchSearchResults(
	queryOrUrl: string,
	sendGTM: boolean = false,
) {
	const data = await fetchData<SearchResults>(
		queryOrUrl.includes(API_URL) ? queryOrUrl : getSearchUrl(queryOrUrl),
		{ method: 'POST' },
	);

	if (sendGTM) {
		if (data?.categorySearchResponse?.categories?.length) {
			pushToGTM({ type: 'search_term_category_view' });
		}
		if (data?.brandSearchResponse?.brands?.length) {
			pushToGTM({ type: 'search_term_brand_view' });
		}
	}

	return data;
}

export function useSearchProductsPageSize() {
	const isSmallOrSmaller = useMaxWidth('md');
	return isSmallOrSmaller ? 4 : 5;
}
export function useSearchTermsPageSize() {
	const isSmallOrSmaller = useMaxWidth('md');
	return isSmallOrSmaller ? 4 : 7;
}

/** Hook for the primary product search. */
export function useSearch(query: string) {
	const productsPageSize = useSearchProductsPageSize();
	const termsPageSize = useSearchTermsPageSize();
	const { data, error, isLoading } = useSWRImmutable(
		query
			? getSearchUrl(query, {
					withCategoriesAndBrands: true,
					pageSize: productsPageSize,
				})
			: null,
		(url) => fetchSearchResults(url, true),
	);

	const categories = useMemo(
		() => filterTruthy(data?.categorySearchResponse?.categories, 'url'),
		[data?.categorySearchResponse?.categories],
	);
	const brands = useMemo(
		() => filterTruthy(data?.brandSearchResponse?.brands, 'url'),
		[data?.brandSearchResponse?.brands],
	);
	const products = useMemo(
		() => filterTruthy(data?.productSearchResponse?.products, 'url'),
		[data?.productSearchResponse?.products],
	);

	return {
		searchTerms:
			data?.autocompleteQueries?.slice(0, termsPageSize) ?? empty.array,
		categories,
		brands,
		products,
		totalProductCount: data?.productSearchResponse?.total ?? 0,
		spellingSuggestions:
			data?.productSearchResponse?.spellingSuggestions ?? empty.array,
		isLoading,
		error,
	};
}
