import fetchData, { FormattedValidationErrors } from 'utils/fetchData';

/** Represents a failed computation. */
export interface Left<T, E> {
	type: 'error';
	error: E;

	/** * Returns true if the Result is an error, false otherwise. */
	isErr(this: Result<T, E>): this is Left<T, E>;

	/** * Returns true if the Result is successful, false otherwise. */
	isOk(this: Result<T, E>): this is Right<T, E>;

	/** * Returns the value of the Result if it is successful, otherwise throws an error. */
	unwrap(): T;

	/** * Returns the value of the Result if it is successful, otherwise returns the provided default value. */
	unwrapOr(defaultValue: T): T;

	/** * Returns the value of the Result if it is successful, otherwise calls the provided function with the error and returns its result. */
	unwrapOrElse(fn: (error: E) => T): T;
}

/** Represents a successful computation. */
export interface Right<T, E> {
	type: 'ok';

	/** * Returns true if the Result is an error, false otherwise. */
	isErr(this: Result<T, E>): this is Left<T, E>;

	/** * Returns true if the Result is successful, false otherwise. */
	isOk(this: Result<T, E>): this is Right<T, E>;

	/** * Returns the value of the Result. */
	unwrap(): T;

	/** * Returns the value of the Result. */
	unwrapOr(defaultValue: T): T;

	/** * Returns the value of the Result. */
	unwrapOrElse(fn: (error: E) => T): T;

	value: T;
}

type Result<T, E> = Left<T, E> | Right<T, E>;

/** Creates a successful Result with the given value.
 * @param value The value of the successful computation.
 * @returns A Result with the 'ok' type and the provided value. */
export function Ok<T, E>(value: T): Result<T, E> {
	return {
		type: 'ok',
		value,
		unwrap: () => value,
		unwrapOr: () => value,
		unwrapOrElse: () => value,
		isErr: () => false,
		isOk: () => true,
	};
}

/**
 * Creates a failed Result with the given error.
 * @param error The error that caused the computation to fail.
 * @returns A Result with the 'error' type and the provided error.
 */
export function Err<T, E>(error: E): Result<T, E> {
	return {
		type: 'error',
		error,
		unwrap: () => {
			throw error;
		},
		unwrapOr: (defaultValue: T) => defaultValue,
		unwrapOrElse: (fn: (error: E) => T) => fn(error),
		isErr: () => true,
		isOk: () => false,
	};
}

export default async function fetchResult<T>(
	url: string,
	options: RequestInit,
): Promise<Result<T, FormattedValidationErrors>> {
	const res = await fetchData(url, options, true).catch((error) => error);
	if (!res || res.status >= 500) {
		return Err({
			businessLogicErrors: [{ key: 'UnknownError', text: 'UnknownError' }],
		});
	}
	if (res.status === 400) {
		return Err(res);
	}
	if (res.status === 200) {
		return Ok(res.responseData as T);
	}
	return Err({ status: res.status });
}
