import { parse } from 'cookie';
import { waitFor } from 'xstate/lib/waitFor';

import { publicRuntimeConfig } from 'config';
import type { BusinessLogicError } from 'models/api';
import {
	formatValidationError,
	generateConsentHeader,
	parseConsentString,
	sendGlobalEvent,
} from 'utils/helpers';

export const API_URL: string =
	publicRuntimeConfig?.NEXT_PUBLIC_DIGITAL_PLATFORM_API_URL || '/';

export interface ExtendedResponse<T> {
	responseData: T;
	status: number;
}
export interface ExtendedResponseError {
	errorText: string;
	headers: Headers;
	status: number;
}
export interface FormattedValidationErrors {
	businessLogicErrors?: BusinessLogicError[];
	fieldValidationErrors?: Record<string, string[]>;
	status?: number;
}

export async function ensureFreshToken() {
	if (typeof window !== 'undefined' && window.globalFetchLockService) {
		const tokenExpiry = window.localStorage.getItem('tokenExpiry');
		if (!tokenExpiry || Date.now() > Number.parseInt(tokenExpiry, 10)) {
			sendGlobalEvent('refresh-token');
		}
		await waitFor(
			window.globalFetchLockService,
			(state) => state.matches('unlocked'),
			{ timeout: 20_000 },
		);
	}
}

// TODO: T should default to unknown
async function fetchData<T = void>(
	url: string,
	config: RequestInit = {},
	extendedResponse = false,
	retries = 0,
): Promise<T | undefined> {
	// Copy to avoid modifying the passed object.
	const options: RequestInit = { ...config };
	const headers: any = {
		'pragma': 'no-cache',
		'cache-control': 'no-cache',
		'Content-Type': 'application/json',

		'CF-Access-Client-Id': publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_ID,
		'CF-Access-Client-Secret':
			publicRuntimeConfig?.NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET,
	};
	// Detta ser skumt ut, men 'multipart/form-data' måste sättas av fetch metoden
	// själv för att boundry, som är en del av headern, ska sättas korrekt, därför
	// sätts application/json som default, om multipart/form-data headern inte är
	// del av config då vi istället tar bort allt och låter fetch sköta headers själv.
	if (options?.headers?.['Content-Type'] === 'multipart/form-data') {
		delete options.headers['Content-Type'];
		delete headers['Content-Type'];
	}
	options.headers = options.headers
		? { ...options.headers, ...headers }
		: headers;

	// TODO: figure out if we need to check for document
	if (typeof document !== 'undefined') {
		options.headers = {
			...options.headers,
			...generateConsentHeader(
				parseConsentString(parse(document.cookie).OptanonConsent),
			),
		};
	}

	await ensureFreshToken();

	return new Promise((resolve, reject) => {
		fetch(url, { ...options, cache: 'no-store', credentials: 'include' })
			.then(async (response) => {
				if (!response.ok) {
					// If it looks like we have an authorization error we send an event to ask the authentication machine to check status.
					if (response.status === 401) {
						console.warn(response);
						if (retries < 2) {
							sendGlobalEvent('refresh-token');
							return fetchData(url, options, extendedResponse, retries + 1);
						}
						sendGlobalEvent('logout');

						// TODO: Handle error message
					}
					if (response.status === 400) {
						console.warn(response);

						try {
							const data = await response.json();

							return reject({
								...formatValidationError(data),
								status: response.status,
							});
						} catch {
							return reject();
						}
					}
					return extendedResponse
						? reject({
								status: response.status,
								errorText: response.statusText,
								headers: response.headers,
							} as ExtendedResponseError)
						: reject(new Error(`${response.status}: ${response.statusText} `));
				}
				return response
					.json()
					.then((data) =>
						extendedResponse
							? { ...data, responseData: data, status: response.status }
							: data,
					)
					.catch((error) => {
						if (response.status === 204) {
							return resolve(undefined);
						}
						console.error(error);
						if (!response.ok) {
							return extendedResponse
								? reject({
										status: response.status,
										errorText: response.statusText,
										headers: response.headers,
									} as ExtendedResponseError)
								: reject(
										new Error(`${response.status}: ${response.statusText} `),
									);
						}
						return extendedResponse
							? reject({
									status: response.status,
									errorText: response.statusText,
								})
							: reject(new Error(error));
					});
			})
			.then((data) => {
				if (!data) {
					return resolve(undefined);
				}
				if (data.Success) {
					return resolve(data.Data);
				}
				if (data.SystemMessages) {
					console.error(data.SystemMessages);

					const errorMsg = data.SystemMessages.map((msg: any) => msg.Message);
					return reject(new Error(errorMsg));
				}
				if (data.Message) {
					console.error(data.Message);

					const error = JSON.stringify(data.ModelState);
					return reject(new Error(error));
				}
				return resolve(data);
			})
			.catch((error) => {
				console.error(error);
				return reject(error);
			});
	});
}

export default fetchData;
