import React, {
	createContext,
	type ReactNode,
	useContext,
	useMemo,
} from 'react';
import { useInterpret } from '@xstate/react';
import type { AnyInterpreter } from 'xstate';

import type { SitecoreContextData } from 'models/sitecoreContext';
import {
	authMachine,
	type AuthMachineActor,
	userMachine,
	type UserMachineActor,
} from 'state-machines/authentication';
import { cartMachine, CartMachineActor } from 'state-machines/cart';
import {
	globalPopoverMachine,
	type GlobalPopoverMachineActor,
} from 'state-machines/global-popover.machine';
import {
	menuMachine,
	type MenuMachineActor,
} from 'state-machines/menu.machine';
import {
	wishlistMachine,
	type WishlistMachineActor,
} from 'state-machines/wishlist';

interface GlobalStateContextType {
	authService: AuthMachineActor;
	cartService: CartMachineActor;
	globalPopoverService: GlobalPopoverMachineActor;
	myMenuService: MenuMachineActor;
	userService: UserMachineActor;
	wishlistService: WishlistMachineActor;
}

export const GlobalStateContext = createContext<GlobalStateContextType>(
	{} as any,
);

interface Props {
	children: ReactNode;
	sitecoreContext: SitecoreContextData;
}

export function GlobalStateContextProvider({
	children,
	sitecoreContext,
}: Props) {
	const isEditing = sitecoreContext?.pageEditing;
	const globalLinks = sitecoreContext?.globalLinks;
	const userService = useInterpret(userMachine, {
		context: {
			skipAuth: isEditing,
			organizationDetailUrl: globalLinks?.accountDetailsPro || '',
		},
		devTools: true,
	});
	const authService = useInterpret(authMachine, {
		devTools: true,
	});
	const cartService = useInterpret(cartMachine, { devTools: true });
	const globalPopoverService = useInterpret(globalPopoverMachine, {
		context: { globalLinks },
		devTools: true,
	});
	const myMenuService = useInterpret(menuMachine, { devTools: true });
	const wishlistService = useInterpret(wishlistMachine, {
		devTools: true,
	});

	const globalStateContextValue: Record<
		keyof GlobalStateContextType,
		AnyInterpreter
	> = useMemo(
		() => ({
			authService,
			userService,
			cartService,
			globalPopoverService,
			myMenuService,
			wishlistService,
		}),
		[
			authService,
			userService,
			cartService,
			globalPopoverService,
			myMenuService,
			wishlistService,
		],
	);
	return (
		<GlobalStateContext.Provider value={globalStateContextValue}>
			{children}
		</GlobalStateContext.Provider>
	);
}
GlobalStateContextProvider.displayName = 'GlobalStateContextProvider';

export function useGlobalStateContext() {
	return useContext(GlobalStateContext);
}
