/**
 * Services
 */

import { waitFor } from 'xstate/lib/waitFor';

import type {
	ApiJulaModelsCartCartRequestModel,
	ApiJulaModelsCartCartResponseModel,
	ApiJulaModelsCheckoutPaymentDetailsRequest,
} from 'models/api';
import { formatCartForRequest } from 'utils/business-logic';
import fetchData, { API_URL } from 'utils/fetchData';
import { assertUnreachable } from 'utils/helpers';
import { createUrl } from 'utils/url';

import type {
	CheckoutRequestParams,
	CheckoutRequestTypes,
} from './checkout.machine.types';

/**
 * Request checkout api
 * - Method to do various request to the Cart-API.
 *   - Load a cart with POST
 *   - Load a cart with GET and id
 *   - Update the cart with PUT
 *   - Add/remove products in cart with POST
 *   - Checkout the cart
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html
 */
export function requestCheckoutApi(
	reqType: CheckoutRequestTypes,
	params: CheckoutRequestParams = {},
): any {
	let apiPath = '';
	let method = '';
	let body = '';
	let updatedCart: ApiJulaModelsCartCartRequestModel = {};
	const headers: any = {
		Accept: 'application/json',
	};
	if (params.cart) {
		updatedCart = formatCartForRequest(params.cart);
	}
	switch (reqType) {
		case 'fetchMiniCart':
			apiPath = `${API_URL}Cart/mini`;
			method = 'GET';
			break;

		case 'fetchCart':
			if (params.id) {
				apiPath = `${API_URL}Cart/${params.id}`;
				method = 'GET';
				break;
			} else {
				apiPath = `${API_URL}Cart`;
				method = 'POST';
				break;
			}

		case 'updateCart':
			apiPath = `${API_URL}Cart/${params.cart?.id}`;
			method = 'PUT';
			body = JSON.stringify(updatedCart);
			break;

		case 'addOneFromBuyButton':
			apiPath = createUrl(`${API_URL}Cart/mini/variants/${params.variantId}`, {
				qty: 1,
				setDistinctValue: 'false',
				returnAccessories: params.requestAdditionalSales ? 'true' : 'false',
				customizationId: params.customizationId,
			});
			method = 'POST';
			break;

		case 'addRemoveCart':
			apiPath = createUrl(`${API_URL}Cart/variants/${params.variantId}`, {
				qty: params.qty,
				setDistinctValue: params.distinctValue ? 'true' : 'false',
				customizationId: params.customizationId,
			});
			method = 'POST';
			break;

		case 'checkoutCart':
			apiPath = `${API_URL}Cart/checkout`;
			method = 'POST';
			body = JSON.stringify(updatedCart);
			break;

		default:
			assertUnreachable(reqType);
	}

	return new Promise<void>((resolve, reject) => {
		fetchData(apiPath, {
			method,
			headers,
			...(body && { body }),
		})
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
}

/**
 * Verify redirect payment
 * - Verifies a redirect payment and returns the full cart.
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__verifyredirectpayment
 */
export function verifyRedirectPayment(
	cartId: string,
	payload: ApiJulaModelsCheckoutPaymentDetailsRequest | undefined,
): Promise<any> {
	const payloadString = payload ? JSON.stringify(payload) : undefined;
	return new Promise((resolve, reject) => {
		fetchData(`${API_URL}Cart/${cartId}/verifyredirectpayment`, {
			method: 'POST',
			body: payloadString,
		})
			.then((data: any) => resolve(data))
			.catch(() => reject());
	});
}

/**
 * Copy cart
 * - Copies cart (e.g. after requesting /Cart/checkout the cart is locked and can't be modified,
 *   so if a payment failes we need to copy it to be able to make changes to it).
 * - @see: https://apigw-fe-dev01.juladev.se/api/v1/docs/index.html#operations-Cart-post_api_v1_Cart__cartId__copy
 */
export function copyCart(
	cartId: string,
): Promise<ApiJulaModelsCartCartResponseModel> {
	return new Promise((resolve, reject) => {
		fetchData(`${API_URL}Cart/${cartId}/copy`, { method: 'POST' }, true)
			.then((data: any) => resolve(data))
			.catch((error) => reject(error));
	});
}

export function waitForUserInfoActor(actor: any) {
	return waitFor(actor, (state) => state.matches('idle'), {
		timeout: 120_000,
	});
}
