import getConfig from 'next/config';

const { publicRuntimeConfig: rawConfig } = getConfig();

interface PublicRuntimeConfig {
	ADYEN_CLIENT_KEY?: string;
	ADYEN_LOCALE?: string;
	ENVIRONMENT_TYPE?: string;
	NEXT_PUBLIC_APPINSIGHTS_CONNECTIONSTRING?: string;
	NEXT_PUBLIC_AUTH_TOKEN_COOKIE_NAME: string;
	NEXT_PUBLIC_CDN_CACHE_BYPASS_COOKIE_NAME?: string;
	NEXT_PUBLIC_CDN_CACHE_BYPASS_COOKIE_USERS?: string;
	NEXT_PUBLIC_CF_ACCESS_CLIENT_ID?: string;
	NEXT_PUBLIC_CF_ACCESS_CLIENT_SECRET?: string;
	NEXT_PUBLIC_CONNECT_JULACLUB_LOGIN_OPTIONS?: string;
	NEXT_PUBLIC_CONNECT_JULAPRO_LOGIN_OPTIONS?: string;
	NEXT_PUBLIC_CONNECT_LOGIN_OPTIONS?: string;
	NEXT_PUBLIC_CUSTOMER_SERVICE_CHAT_ID?: string;
	NEXT_PUBLIC_CUSTOMER_SERVICE_ZENDESK_CHAT_ID?: string;
	NEXT_PUBLIC_DEFAULT_LANGUAGE?: string;
	NEXT_PUBLIC_DIGITAL_PLATFORM_API_URL: string;
	NEXT_PUBLIC_FATHOM_CONTAINER_ID?: string;
	NEXT_PUBLIC_GOOGLE_MAPS_API_KEY?: string;
	NEXT_PUBLIC_GTM_CONTAINER_ID?: string;
	NEXT_PUBLIC_GTM_JULA?: string;
	NEXT_PUBLIC_JSS_REQUEST_ITEM_PATH_PREFIX?: string;
	NEXT_PUBLIC_JULA_CONNECT_CLIENT_ID: string;
	NEXT_PUBLIC_JULA_CONNECT_OAUTH_URL: string;
	NEXT_PUBLIC_JULA_CONNECT_TEST_MODE?: string;
	NEXT_PUBLIC_JULA_MARKET_CODE?: string;
	NEXT_PUBLIC_JULA_MARKET_LANGUAGE?: string;
	NEXT_PUBLIC_JULA_PRO_TEST_MODE?: string;
	NEXT_PUBLIC_LOCAL_PROTOCOL_COMPUTERNAME?: string;
	NEXT_PUBLIC_LOCALES?: string;
	NEXT_PUBLIC_ONETRUST_KEY?: string;
	NEXT_PUBLIC_PUBLIC_URL: string;
	NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_LIFETIME?: string;
	NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_MAX_LIFETIME?: string;
	NEXT_PUBLIC_REFRESH_TOKEN_COOKIE_NAME: string;
	NEXT_PUBLIC_SHOW_REFERENCE?: string;
	NEXT_PUBLIC_VIDEOLY_ID?: string;
	REFRESHTOKEN_SKIP_SAME_SITE_POLICY?: boolean;
}

// Named export to always use the same name as from Next.
// eslint-disable-next-line import/prefer-default-export
export const publicRuntimeConfig: PublicRuntimeConfig | undefined = rawConfig;
