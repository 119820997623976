import { Field } from '@sitecore-jss/sitecore-jss-nextjs';
import type { ActorRef, State } from 'xstate';
import { assign, createMachine } from 'xstate';

import type { JulaSitecoreContextValue } from 'lib/component-props';
import { GlobalLinks } from 'models/sitecoreContext';
import fetchData from 'utils/fetchData';

interface PopoverItem {
	fields?: {
		heading?: Field<string>;
	};
}
interface GlobalPopoverMachineContext {
	data?: JulaSitecoreContextValue;
	error: Error | undefined;
	globalLinks: GlobalLinks | undefined;
	heading?: string;
	target?: string;
}

type GlobalPopoverMachineEvents =
	| {
			type: 'OPEN';
			heading: string;
			target: string;
	  }
	| {
			type: 'CLOSE';
	  }
	| { type: 'done.invoke.fetchTarget'; data: JulaSitecoreContextValue };

export interface GlobalPopoverMachineGlobalEvents {
	'global-popover-open-global-link': CustomEvent<{
		globalLink: keyof GlobalLinks;
	}>;
	'global-popover-open-target': CustomEvent<{
		heading: string;
		target: string;
	}>;
}

export type GlobalPopoverMachineState = State<
	GlobalPopoverMachineContext,
	GlobalPopoverMachineEvents
>;
export type GlobalPopoverMachineActor = ActorRef<
	GlobalPopoverMachineEvents,
	GlobalPopoverMachineState
>;

const fetchTarget = (target?: string): Promise<any> => {
	const apiPath = `/api/proxy/?item=${target}&sc_mode=view`;

	return new Promise((resolve, reject) => {
		fetchData(apiPath, {
			method: 'GET',
			headers: {
				'Content-Type': 'application/json',
			},
		})
			.then((data) => resolve(data))
			.catch((error) => reject(error));
	});
};

// Selectors
export const selectIsLoading = (state: GlobalPopoverMachineState) =>
	state.matches('loading');

export const selectIsClosing = (state: GlobalPopoverMachineState) =>
	state.matches('closing');

export const selectIsOpen = (state: GlobalPopoverMachineState) =>
	!state.matches('idle') && !selectIsClosing(state);

export const selectHeading = (state: GlobalPopoverMachineState) =>
	state.context.heading ||
	(state.context.data?.route?.placeholders?.['jula-main']?.[0] as PopoverItem)
		?.fields?.heading?.value;

export const selectTargetIsLoaded = (state: GlobalPopoverMachineState) =>
	state.matches('targetLoaded');

export const selectRoute = (state: GlobalPopoverMachineState) =>
	state.context.data?.route;

// global popover machine
export const globalPopoverMachine = createMachine(
	{
		id: 'globalPopoverMachine',
		schema: {
			context: {} as GlobalPopoverMachineContext,
			events: {} as GlobalPopoverMachineEvents,
		},
		tsTypes: {} as import('./global-popover.machine.typegen').Typegen0,
		initial: 'idle',
		invoke: {
			src: 'globalEventListener',
		},
		states: {
			idle: {},
			loading: {
				invoke: {
					id: 'fetchTarget',
					src: 'fetchTarget',
					onDone: [
						{
							actions: 'setData',
							target: 'targetLoaded',
						},
					],
					onError: [
						{
							actions: 'setError',
							target: 'error',
						},
					],
				},
			},
			targetLoaded: {},
			error: {},
			closing: {
				id: 'closing',
				after: {
					1: { target: 'idle' },
				},
			},
		},
		on: {
			OPEN: [
				{
					actions: 'clearError',
					cond: 'hasAlreadyLoadedTarget',
					target: '.targetLoaded',
				},
				{
					actions: ['clearError', 'clearData', 'setTarget'],
					target: '.loading',
				},
			],
			CLOSE: {
				actions: 'clearError',
				target: '#closing',
			},
		},
	},
	{
		actions: {
			setTarget: assign({
				target: (_context, event) => event.target,
				heading: (_context, event) => event.heading,
			}),
			setData: assign({
				data: (_context, { data }) => data.sitecore as JulaSitecoreContextValue,
			}),
			setError: assign({
				error: (_context, { data }) => (data as Error) || null,
			}),
			clearData: assign({
				data: (_context, _event) => undefined,
			}),
			clearError: assign({
				error: (_context, _event) => undefined,
			}),
		},
		guards: {
			hasAlreadyLoadedTarget: (context, event) =>
				context.target === event.target && context.data !== undefined,
		},
		services: {
			fetchTarget: (context) => fetchTarget(context.target),
			globalEventListener: (context) => (sendBack) => {
				if (typeof window === 'undefined') {
					return undefined;
				}
				const openTarget = (
					event: WindowEventMap['global-popover-open-target'],
				) => {
					sendBack({
						type: 'OPEN',
						target: event.detail.target,
						heading: event.detail.heading,
					});
				};
				const openGlobalLink = (
					event: WindowEventMap['global-popover-open-global-link'],
				) => {
					const target = context.globalLinks?.[event.detail.globalLink];
					if (!target) {
						return;
					}
					sendBack({
						type: 'OPEN',
						target,
						// TODO: is this a problem? Can't reproduce a missing heading where
						// this event is used.
						heading: '',
					});
				};

				window.addEventListener(
					'global-popover-open-global-link',
					openGlobalLink,
				);
				window.addEventListener('global-popover-open-target', openTarget);

				return () => {
					window.removeEventListener(
						'global-popover-open-global-link',
						openGlobalLink,
					);
					window.removeEventListener('global-popover-open-target', openTarget);
				};
			},
		},
	},
);
