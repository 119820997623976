import { useCallback, useEffect } from 'react';

import { useChatContext, useGlobalStateContext } from 'contexts';
import { useChatAvailability, useGlobalEvent, useGlobalLinks } from 'hooks';
import { useI18n } from 'utils/i18n';

export function useChat(isActive = false) {
	const { t } = useI18n();
	const { globalPopoverService } = useGlobalStateContext();
	const { customerChat } = useGlobalLinks();
	const { isOpen, isAvailable, zendeskJwt, isLoading } =
		useChatAvailability(isActive);
	const {
		messengerIsOpen,
		setMessengerIsOpen,
		setMessageCount,
		messageCount,
		hasOpenedMessenger,
		setHasOpenedMessenger,
	} = useChatContext();

	useEffect(() => {
		if (zendeskJwt) {
			window.zE('messenger', 'loginUser', (callback) => {
				callback(zendeskJwt);
			});
		}
	}, [zendeskJwt]);

	useGlobalEvent('set-show-zendesk-chat-button', (e) => {
		setMessengerIsOpen(e.detail);
	});

	useGlobalEvent('set-zendesk-chat-message-count', (e) => {
		setMessageCount(e.detail);
	});

	const openChat = useCallback(() => {
		window.zE('messenger', 'open');
		setHasOpenedMessenger();
	}, [setHasOpenedMessenger]);

	const openGlobalPopover = useCallback(() => {
		globalPopoverService.send({
			type: 'OPEN',
			target: customerChat || '',
			heading: t('customer_service_chat_popover_heading'),
		});
	}, [customerChat, globalPopoverService, t]);

	return {
		// zendesk chat system availability
		chatIsAvailable: isAvailable,
		// zendesk chat system open status
		chatIsOpen: isOpen,
		chatAvailabilityIsLoading: isLoading,
		setHasOpenedMessenger,
		openChatOrPopover: hasOpenedMessenger ? openChat : openGlobalPopover,
		// zendesk client chat window
		messengerIsOpen,
		messageCount,
		hasOpenedMessenger,
	};
}
