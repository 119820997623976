import { useCallback } from 'react';
import { waitFor } from 'xstate/lib/waitFor';

import { useGlobalStateContext } from 'contexts';
import {
	type GTMItemListId,
	type GTMItemListName,
	type GTMListProduct,
	type GTMPriceProduct,
	pushToGTM,
} from 'utils/GoogleTagManager';
import { ignorePromiseRejection, sleep } from 'utils/helpers';

/**
 * Helpers for sending view_item_list, select_item and add_to_cart events
 * for product lists.
 */
export function useProductListGTMEvents(
	itemListId: GTMItemListId,
	itemListName: GTMItemListName,
) {
	const { cartService, wishlistService } = useGlobalStateContext();

	const sendViewItemListEvent = useCallback(
		(products: GTMListProduct[], pageSize: number) => {
			if (products.length > 0 && pageSize > 0) {
				pushToGTM({
					type: 'view_item_list',
					payload: {
						itemListId,
						itemListName,
						pageSize,
						products,
					},
				});
			}
		},
		[itemListId, itemListName],
	);

	const sendSelectItemEvent = useCallback(
		(product: GTMListProduct, productIndex: number) => {
			pushToGTM({
				type: 'select_item',
				payload: {
					itemListId,
					itemListName,
					product,
					productIndex,
				},
			});
		},
		[itemListId, itemListName],
	);

	const sendAddToCartEventOnIdle = useCallback(
		async (product: GTMPriceProduct, quantity: number = 1) => {
			// Wait a bit in case this helper gets called before the machine moves
			// away from idle, e.g.
			// sendAddToCartEvent(...);
			// cartService.send(...)
			await sleep(50);
			const cartState = await waitFor(cartService, (state) =>
				state.matches('idle'),
			);
			pushToGTM({
				type: 'add_to_cart',
				payload: {
					cartId: cartState.context.id,
					itemListId,
					itemListName,
					product,
					quantity,
				},
			});
		},
		[cartService, itemListId, itemListName],
	);
	// Wrapper to keep the async internal.
	const sendAddToCartEvent = useCallback(
		(product: GTMPriceProduct, quantity: number = 1) => {
			ignorePromiseRejection(sendAddToCartEventOnIdle(product, quantity));
		},
		[sendAddToCartEventOnIdle],
	);

	const sendAddToWishlistEventOnIdle = useCallback(
		async (product: GTMPriceProduct, quantity: number = 1) => {
			// Wait a bit in case this helper gets called before the machine moves
			// away from idle, e.g.
			// sendAddToWishlistEvent(...);
			// wishlistService.send(...)
			await sleep(50);
			await waitFor(wishlistService, (state) =>
				state.matches('addOneToWishlist.idle'),
			);
			pushToGTM({
				type: 'add_to_wishlist',
				payload: {
					itemListId,
					itemListName,
					product,
					quantity,
				},
			});
		},
		[itemListId, itemListName, wishlistService],
	);
	// Wrapper to keep the async internal.
	const sendAddToWishlistEvent = useCallback(
		(product: GTMPriceProduct, quantity: number = 1) => {
			ignorePromiseRejection(sendAddToWishlistEventOnIdle(product, quantity));
		},
		[sendAddToWishlistEventOnIdle],
	);

	return {
		sendViewItemListEvent,
		sendSelectItemEvent,
		sendAddToCartEvent,
		sendAddToWishlistEvent,
		// Send them back out for convenience, here they're typed instead of
		// just plain strings.
		gtmItemListId: itemListId,
		gtmItemListName: itemListName,
	};
}
