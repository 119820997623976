import { publicRuntimeConfig } from 'config';

import { is } from './helpers';
import { round } from './math';
import { ENTITIES, makeSpacesNoBreak } from './string';

// Available units: https://tc39.es/ecma402/#table-sanctioned-single-unit-identifiers

// An undefined locale will fall back to 'the implementation's default' so
// force Swedish for tests to not depend on the current environment.
export const LOCALE =
	process.env.NODE_ENV === 'test'
		? 'sv-SE'
		: publicRuntimeConfig?.NEXT_PUBLIC_JULA_MARKET_LANGUAGE;

const INTEGER_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'decimal',
	maximumFractionDigits: 0,
});
const SINGLE_DECIMAL_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'decimal',
	maximumFractionDigits: 1,
});

/**
 * Format a number according to the current locale. Affects things like decimal
 * and thousand separators.
 */
export function formatNumber(
	num: number,
	type: 'decimal' | 'integer' = 'decimal',
): string {
	const formatter =
		type === 'decimal' ? SINGLE_DECIMAL_FORMATTER : INTEGER_FORMATTER;
	return formatter.format(num);
}

const CENTIMETER_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'centimeter',
});

/**
 * Format a length in centimeters.
 */
export function formatLength(value: number | undefined) {
	return value ? makeSpacesNoBreak(CENTIMETER_FORMATTER.format(value)) : '';
}

const GRAM_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'gram',
});
const KILOGRAM_FORMATTER = new Intl.NumberFormat(LOCALE, {
	style: 'unit',
	unit: 'kilogram',
});

/**
 * Format a weight in grams or kilograms.
 */
export function formatWeight(value: number | undefined) {
	if (value === undefined) {
		return '';
	}
	return makeSpacesNoBreak(
		value < 1
			? GRAM_FORMATTER.format(value * 1000)
			: KILOGRAM_FORMATTER.format(value),
	);
}

const NUMERIC_DATE_FORMATTER = new Intl.DateTimeFormat(LOCALE, {
	dateStyle: 'short',
});

/**
 * Format a numeric date, e.g. '2012-12-20' for sv-SE or '2/20/2012' for en-US.
 */
export function formatDate(date: string | Date | undefined) {
	return date ? NUMERIC_DATE_FORMATTER.format(new Date(date)) : '';
}

const NUMERIC_DATE_TIME_FORMATTER = new Intl.DateTimeFormat(LOCALE, {
	dateStyle: 'short',
	timeStyle: 'short',
});

/**
 * Format a numeric date and time, e.g. '2012-12-20 04:00' for sv-SE or
 * '12/20/12, 4:00 AM' for en-US.
 */
export function formatDateAndTime(date: string | Date | undefined) {
	return date ? NUMERIC_DATE_TIME_FORMATTER.format(new Date(date)) : '';
}

const RELATIVE_TIME_FORMATTER = new Intl.RelativeTimeFormat(LOCALE, {
	numeric: 'auto',
});

/**
 * Format a date relative to now, like 'tomorrow' or '2 weeks ago'.
 */
export function formatElapsedTime(pastDate: string | Date) {
	const diffInMilliseconds = Date.now() - new Date(pastDate).getTime();
	const diffInDays = diffInMilliseconds / (1000 * 60 * 60 * 24);

	if (diffInDays < 7) {
		return RELATIVE_TIME_FORMATTER.format(-round(diffInDays), 'day');
	}
	if (diffInDays < 30) {
		return RELATIVE_TIME_FORMATTER.format(-round(diffInDays / 7), 'week');
	}
	if (diffInDays < 365) {
		return RELATIVE_TIME_FORMATTER.format(-round(diffInDays / 30), 'month');
	}
	return RELATIVE_TIME_FORMATTER.format(-round(diffInDays / 365), 'year');
}

const LANGUAGE_FORMATTER = new Intl.DisplayNames(LOCALE, { type: 'language' });

/**
 * Format a language code to its full name according to local, e.g. 'en' to 'Engelska'.
 */
export function formatLanguage(language: string) {
	return LANGUAGE_FORMATTER.of(language);
}

const REGION_FORMATTER = new Intl.DisplayNames(LOCALE, { type: 'region' });

/**
 * Format a region code to its full name according to local, e.g. 'SE' to 'Sverige'.
 */
export function formatRegion(region: string) {
	return REGION_FORMATTER.of(region);
}

/**
 * Format a raw byte value to a human friendly string.
 *
 * Core logic from https://github.com/sindresorhus/pretty-bytes.
 *
 * @example
 *
 * formatBytes(123456)
 * // => '123 kB'
 */
export function formatBytes(bytes: number | undefined | null): string {
	if (!is.number(bytes)) {
		return '';
	}
	const units = ['B', 'kB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

	const isNegative = bytes < 0;
	const prefix = isNegative ? ENTITIES.minus : '';

	let num = bytes;
	if (isNegative) {
		num = -num;
	}

	if (num < 1) {
		return prefix + formatNumber(num) + ENTITIES.noBreakSpace + units[0];
	}

	const exponent = Math.min(Math.floor(Math.log10(num) / 3), units.length - 1);
	num /= 1000 ** exponent;
	const unit = units[exponent];

	return (
		prefix +
		formatNumber(Number(num.toPrecision(3)), 'integer') +
		ENTITIES.noBreakSpace +
		unit
	);
}

const templatePartsRegexp = /{{(.*?)}}/g;

/**
 * Format a string with mustache placeholders.
 *
 * @example
 *
 * formatTemplate('{{name}} is {{years}} years old', {
 *   name: 'Joe',
 *   years: 97,
 * })
 * // => 'Joe is 97 years old'
 */
export function formatTemplate(
	str: string,
	// TODO: disallow null and undefined?
	params: Record<string, string | number | null | undefined>,
) {
	return str.replaceAll(templatePartsRegexp, (fullMatch, group1: string) => {
		const key = group1.trim();
		return key && is.defined(params[key]) ? String(params[key]) : '';
	});
}
