/**
 * NOTE! This file is imported in the Tailwind config which does not go through
 * the main TypeScript setup. Importing things like utils is not possible here.
 */

export const COLORS = {
	// https://www.hexcolortool.com/ for finding the hex for the hover/darken state of the buttons.

	white: '#ffffff',
	black: '#111111',

	// Brand colors:
	julaLogotypeRed: '#d22630',
	julaRedLight: '#e05353',
	julaRed: '#cb333b',
	julaRedDark: '#ab2530',
	julaRedDarker: '#8f222e',

	// Section colors:
	toolsMachines: '#41b6e6',
	constructionPaint: '#0077c8',
	electricsLightning: '#ffb81c',
	clothesSafety: '#ff8200',
	garden: '#c4d600',
	leisure: '#509e2f',
	autoGarage: '#686e9f',
	homeHousehold: '#6d2077',

	// Grey:
	greyLighter: '#e7e7e7',
	greyLight: '#d1d1d1',
	grey: '#707070',
	greyDark: '#4f4f4f',
	greyDarker: '#262626',

	cta: '#026532',
	ctaDarker: '#003919',

	successLighter: '#dff4d3',
	success: '#026532',
	successDarker: '#003919',

	informationLighter: '#fff2c6',
	information: '#a1490b',
	informationDarker: '#461d04',

	errorLighter: '#fce9e7',
	error: '#8f222e',
	errorDarker: '#440d13',

	campaign: '#ffe500',
	news: '#ffb81c',

	// Opacity:
	blackOpacity0: 'rgba(0, 0, 0, 0)',
	blackOpacity20: 'rgba(17, 17, 17, 0.2)',
};

function mapColors<T extends object>(obj: T, mapper: (key: keyof T) => string) {
	const result = {} as Record<keyof T, string>;
	Object.keys(obj).forEach((key) => {
		result[key] = mapper(key as keyof T);
	});
	return result;
}

export const BACKGROUND_COLOR_CLASSES = mapColors(COLORS, (key) => `bg-${key}`);

export const TEXT_COLOR_CLASSES = mapColors(COLORS, (key) => `text-${key}`);
