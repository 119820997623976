import { assign, createMachine, sendParent } from 'xstate';

import { ApiJulaModelsCartCartErrorModel } from 'models/api';

import {
	DeliveryMachineContext,
	DeliveryMachineEvents,
} from './delivery.types';

/** delivery machine */
export const deliveryMachine = createMachine(
	{
		id: 'delivery',
		initial: 'idle',
		schema: {
			context: {} as DeliveryMachineContext,
			events: {} as DeliveryMachineEvents,
		},
		tsTypes: {} as import('./delivery.machine.typegen').Typegen0,
		context: {
			deliveryErrorTypes: [
				'DeliveryMethodNotSelected',
				'DeliveryMethodInvalid',
				'DeliveryMethodsRestricted_Dimensions',
				'DeliveryMethodsRestricted_PostalCodeNotExist',
			],
		},
		states: {
			idle: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
					SELECT_DELIVERY_METHOD: {
						target: 'loading',
						actions: ['saveData', 'updateDataOptimistic'],
					},
					SELECT_DELIVERY_PICKUP: {
						target: 'loading',
						actions: 'saveData',
					},
				},
			},
			loading: {
				on: {
					CART_UPDATED: {
						target: 'idle',
						actions: 'updateData',
					},
				},
			},
		},
		on: {
			RESET_TO_OLD: {
				target: 'idle',
				actions: 'resetToOldDelivery',
			},
		},
	},
	{
		actions: {
			updateData: assign({
				cart: (_context, event) => event.value,
				availableDeliveryMethods: (context, event) =>
					event?.value?.availableDeliveryMethods ||
					context?.availableDeliveryMethods,
				selectedDelivery: (context, event) =>
					event?.value?.selectedDelivery || context?.selectedDelivery,
				errors: (context, event) => event?.value?.errorList,
			}),

			updateDataOptimistic: assign({
				selectedDelivery: (context, event) =>
					event?.value || context?.selectedDelivery,
				oldSelectedDelivery: (contxt, event) => event?.value,
			}),

			resetToOldDelivery: assign({
				selectedDelivery: (context) => context.oldSelectedDelivery,
			}),

			saveData: sendParent((_context, event) => ({
				type: 'UPDATE_CART',
				selectedDelivery: event.value,
			})),
		},
	},
);
